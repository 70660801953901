import { animate, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import {
  AlertController,
  MenuController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { ParcoursService } from "./services/parcours.service";
import { PilierService } from "./services/pilier.service";
import { UserService } from "./services/user.service";
import { ScreenSizeService } from "./services/utils/screen-size.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  animations: [
    trigger("fadeAnimation", [
      transition("login => register, register => login", [
        style({ opacity: "0" }),
        animate(500, style({ opacity: "1" })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  version = environment.version;
  public nonAuthenticatedPages = [
    "/first-connect",
    "/general-terms-of-use-and-sales",
    "/not-found",
    "/reset-password",
    "/login",
  ];
  isMobileScreen: boolean;
  mainMenu = "mainMenu";
  environmentMenu = "envMenu";
  readonly environment = environment;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public userService: UserService,
    public translate: TranslateService,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public router: Router,
    public navCtrl: NavController,
    public pilierService: PilierService,
    public parcoursService: ParcoursService,
    private screenSizeService: ScreenSizeService,
    private menuCtrl: MenuController,
  ) {
    const setting = JSON.parse(localStorage.getItem("setting") || null);

    // TODO: Pour chaque nouvelle langue ajouter le code ici
    translate.addLangs(["en", "fr"]);

    // Possibilité d'utiliser la langue du navigateur
    // const browserLang = translate.getBrowserLang() || 'fr';
    translate.setDefaultLang(setting?.language?.code || "fr");

    this.userService.loadUserAuth();

    this.addEventListener();

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await this.translate.get("getConsents").toPromise();
      this.statusBar.styleDefault();
      if (this.userService.isLoggedIn()) {
        this.splashScreen.hide();
      } else {
        this.splashScreen.show();
      }
    });
  }

  ngOnInit() {
    this.initializeApp();
    this.userService.loadUserAuth();
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        console.log("navigateEnd");
        console.log(this.router.url.toString());
        console.log(event.urlAfterRedirects);
        const url = event.urlAfterRedirects;
      }
    });
    if (!this.environment.production) {
      document.documentElement.style.setProperty(
        "--environment-backgroundColor",
        this.environment.backgroundColor,
      );
      document.documentElement.style.setProperty(
        "--environment-textColor",
        this.environment.textColor,
      );
    }
  }

  logout() {
    window.dispatchEvent(new CustomEvent("LOGOUT", { detail: "LOG OUT" }));
  }

  // async checkTermsOfService() {
  //   if (this.userService.user.consents?.isGlobalConsent) {
  //     return;
  //   }
  //   await this.translate.get("getConsents").toPromise();
  //   const alert = await this.alertCtrl.create({
  //     header: this.translate.instant("getConsents"),
  //     message: this.translate.instant("getConsentsMessage"),
  //     backdropDismiss: false,
  //     animated: true,
  //     cssClass: "my-alert-consent",
  //     buttons: [
  //       {
  //         text: this.translate.instant("accept"),
  //         role: "confirm",
  //         handler: async () => {
  //           this.userService.user.consents.isGlobalConsent = true;
  //           // this.userService.updateUser();
  //         },
  //       },
  //       {
  //         text: this.translate.instant("reject"),
  //         role: "cancel",
  //         handler: async () => {
  //           this.userService.user.consents.isGlobalConsent = false;
  //         },
  //       },
  //     ],
  //   });
  //   await alert.present();
  // }

  addEventListener() {
    window.addEventListener("END_OF_LOAD_USER_DATA", async () => {
      // this.checkTermsOfService().then();
      await this.pilierService.loadAllPiliers();
      await this.parcoursService.loadParcours();
    });

    window.addEventListener("CONSENTS_REQUIRED", async () => {
      // TODO si il faut le faire, s'inspirer du code commenté de checkTermsOfService
      // await this.checkTermsOfService();
    });
    window.addEventListener("LOGOUT", async () => {
      const toast = await this.toastCtrl.create({
        message: "Déconnecté!",
        duration: 5000,
        position: "top",
        color: "success",
      });
      // TODO: Se passer de ces lignes en passant par le service Auth-guard
      // Permet de ne pas déconnecter si la page n'était pas censé être protégée par la connexion à la base
      let notProtected = this.nonAuthenticatedPages.find((page) => {
        return this.router.url.startsWith(page);
      });
      if (notProtected == undefined) {
        await toast.present();
        this.userService.logout();
        await this.navCtrl.navigateRoot("login");
      }
    });
  }

  ngAfterViewInit(): void {
    this.isMobileScreen = undefined;
    this.onScreenResize();
  }

  async showEnvironmentMenu() {
    await this.menuCtrl.enable(true, this.environmentMenu);
    await this.menuCtrl.open(this.environmentMenu);
  }

  async enableMainMenu() {
    await this.menuCtrl.enable(true, this.mainMenu);
  }

  @HostListener("window:resize", ["$event"])
  onScreenResize() {
    const newVal: boolean = window.innerWidth < 768; //Mobile screen is under 768px
    if (newVal != this.isMobileScreen) {
      this.isMobileScreen = newVal;
      this.screenSizeService.updateIsMobile(this.isMobileScreen);
    }
  }
}
