import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  //Durée d'une session en millisecondes
  sessionDuration: number = 1000 * 60 * 30;

  constructor(
    public router: Router,
    private authService: AuthService,
    public userService: UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let isStillActive =
      Date.now() - parseInt(sessionStorage.getItem("lastLogin")) <
      this.sessionDuration;

    if (!this.userService.user || !isStillActive) {
      // this.authService.tmpUrl = this.router.url;

      this.authService.tmpUrl = window.location.href; // On conserve le lien vers la page ciblée, a utiliser une fois connecté
      this.router.navigate(["login"]);
      this.userService.user = null;

      return false;
    }

    return true;
  }
}
