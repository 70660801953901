import { map } from "rxjs/operators";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { StrapiUtilsService } from "./strapi-utils.service";

export type ApiParams = {
  [param: string]:
    | string
    | number
    | boolean
    | ReadonlyArray<string | number | boolean>;
};

@Injectable({
  providedIn: "root",
})
export class StrapiHttpService {
  private strapiBaseUrl = environment.serverApiUrl;

  constructor(
    private http: HttpClient,
    private strapiUtilsService: StrapiUtilsService,
  ) {}

  public async getFirst<T>(url: string, params: ApiParams = {}): Promise<T> {
    return this.http
      .get(this.strapiBaseUrl + url, await this.options(params))
      .pipe(
        map((response) => this.strapiUtilsService.parseResponseData(response)),
        map((data) => data[0]),
      )
      .toPromise();
  }

  public async get<T>(url: string, params: ApiParams = {}): Promise<T> {
    return this.http
      .get(this.strapiBaseUrl + url, await this.options(params))
      .pipe(
        map((response) => this.strapiUtilsService.parseResponseData(response)),
      )
      .toPromise();
  }

  public async getAll<T>(url: string, params: ApiParams = {}): Promise<T[]> {
    return this.http
      .get(this.strapiBaseUrl + url, await this.options(params))
      .pipe(
        map((response) => this.strapiUtilsService.parseResponseData(response)),
      )
      .toPromise();
  }

  public async post<T>(url: string, data: Partial<T>): Promise<T> {
    return this.http
      .post(this.strapiBaseUrl + url, { data: data }, await this.options())
      .pipe(
        map((response) => this.strapiUtilsService.parseResponseData(response)),
      )
      .toPromise();
  }

  public async put<T>(url: string, data: Partial<T>): Promise<T> {
    return this.http
      .put(this.strapiBaseUrl + url, { data: data }, await this.options())
      .pipe(
        map((response) => this.strapiUtilsService.parseResponseData(response)),
      )
      .toPromise();
  }

  private async options(
    params: ApiParams = {},
  ): Promise<{ headers: HttpHeaders; params?: HttpParams }> {
    return {
      headers: await this.headers(),
      params: new HttpParams().appendAll(params),
    };
  }

  protected async headers(): Promise<HttpHeaders> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json; charset=utf-8")
      .set("Accept", "application/json");
    return headers;
  }
}
