<ion-app class="animated fadeIn slower">
  <ion-menu
    contentId="main-content"
    type="overlay"
    side="end"
    id="environmentMenu"
    [menuId]="environmentMenu"
    (ionDidClose)="enableMainMenu()"
    *ngIf="!this.environment.production"
  >
    <ion-header class="ion-padding-horizontal">
      <ion-toolbar>
        <a [routerLink]="['/home']" slot="start">
          <ion-img src="assets/icons/icon-72x72.png" class="logo" slot="start">
          </ion-img>
        </a>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="none" class="ion-text-end">
        <ion-item
          ><ion-label
            >Url :
            <a
              [href]="this.environment.serverUrl"
              target="_blank"
              rel="noopener noreferrer"
              >{{ this.environment.serverUrl }}</a
            ></ion-label
          ></ion-item
        >
        <ion-item
          ><ion-label
            >API :
            <a
              [href]="this.environment.serverApiUrl"
              target="_blank"
              rel="noopener noreferrer"
              >{{ this.environment.serverApiUrl }}</a
            ></ion-label
          ></ion-item
        >
        <ion-item
          ><ion-label class="flex-center"
            >Production :
            <ion-icon
              [name]="
                this.environment.production ? 'checkmark-sharp' : 'close-sharp'
              "
              [ngClass]="this.environment.production ? 'success' : 'error'"
            ></ion-icon></ion-label
        ></ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-menu
    *ngIf="userService.isLoggedIn()"
    contentId="main-content"
    type="overlay"
    side="end"
    [menuId]="mainMenu"
  >
    <ion-header class="ion-padding-horizontal">
      <ion-toolbar>
        <a [routerLink]="['/home']" slot="start">
          <ion-img src="assets/icons/icon-72x72.png" class="logo" slot="start">
          </ion-img>
        </a>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="none" class="ion-text-end">
        <ion-menu-toggle>
          <ion-item button [routerLink]="['/home']" routerLinkActive="active">
            <ion-label>Accueil</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- TODO : abonnement à ajouter dans la V2, désactivé pour la V1 du site -->
        <!-- <ion-menu-toggle *ngIf="!userService.isSubscribed()">
          <ion-item
            button
            [routerLink]="['/abonnement']"
            routerLinkActive="active"
          >
            <ion-icon name="star" class="star ion-margin-end"></ion-icon>
            <ion-label>Abonnement</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle>
          <ion-item
            button
            [routerLink]="['/marguerite']"
            routerLinkActive="active"
          >
            <ion-label>Marguerite</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item
            button
            [routerLink]="['/piliers-de-ma-sante']"
            routerLinkActive="active"
          >
            <ion-label>Les Piliers de Ma Santé</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item
            button
            [routerLink]="['/liens-utiles']"
            routerLinkActive="active"
          >
            <ion-label>Liens utiles</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item
            button
            [routerLink]="['/profile']"
            routerLinkActive="active"
          >
            <ion-chip class="profile">
              <ion-avatar class="avatar"
                ><img [src]="userService.getAvatar()"
              /></ion-avatar>
              <ion-label>Mon profil</ion-label>
            </ion-chip>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item button (click)="logout()">
            <ion-icon name="log-out" class="logout"></ion-icon>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>

  <div id="main-content" class="ion-page">
    <ion-header *ngIf="userService.isLoggedIn()" class="ion-padding-horizontal">
      <ion-toolbar>
        <!-- desktop -->
        <a [routerLink]="['/home']" slot="start">
          <ion-img
            src="assets/img/logo.png"
            class="logo ion-hide-md-down"
          ></ion-img>
        </a>
        <ion-buttons class="ion-hide-md-down" slot="end">
          <ion-button [routerLink]="['/home']" routerLinkActive="active"
            >Accueil</ion-button
          >
          <!-- TODO : abonnement à ajouter dans la V2, désactivé pour la V1 du site -->
          <!-- <ion-button
            *ngIf="!userService.isSubscribed()"
            [routerLink]="['/abonnement']"
            routerLinkActive="active"
          >
            Abonnement
            <ion-icon name="star" class="star ion-margin-start"></ion-icon>
          </ion-button> -->
          <ion-button
            [routerLink]="['/piliers-de-ma-sante']"
            routerLinkActive="active"
            >Les Piliers de Ma Santé</ion-button
          >
          <ion-button [routerLink]="['/liens-utiles']" routerLinkActive="active"
            >Liens utiles</ion-button
          >
          <ion-button [routerLink]="['/profile']" routerLinkActive="active">
            <ion-chip class="profile">
              <ion-avatar class="avatar"
                ><img [src]="userService.getAvatar()"
              /></ion-avatar>
              <ion-label>Mon profil</ion-label>
            </ion-chip>
          </ion-button>
          <ion-button (click)="logout()">
            <ion-icon slot="icon-only" name="log-out" class="logout"></ion-icon>
          </ion-button>
        </ion-buttons>
        <!-- /desktop -->
        <!-- mobile -->
        <a [routerLink]="['/home']" slot="start">
          <ion-img
            src="assets/icons/icon-72x72.png"
            [routerLink]="['/home']"
            class="logo ion-hide-md-up"
            slot="start"
          >
          </ion-img>
        </a>
        <ion-menu-button
          class="ion-hide-md-up"
          [menu]="mainMenu"
          [autoHide]="false"
          slot="end"
        ></ion-menu-button>
        <!-- /mobile -->
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-router-outlet>
        <button
          type="button"
          id="environmentButton"
          class="flex-center"
          (click)="showEnvironmentMenu()"
          *ngIf="!this.environment.production"
        >
          Env
          <ion-icon name="help-circle-outline" class="rotate-right"></ion-icon>
        </button>
      </ion-router-outlet>
    </ion-content>
  </div>
</ion-app>
