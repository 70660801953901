import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  static setItem(key: string, item: any) {
    const cryptedItem = CryptoJS.AES.encrypt(
      JSON.stringify(item),
      environment.encryptionKey,
    );
    localStorage.setItem(key, cryptedItem.toString());
  }

  static getItem(key: string) {
    const cryptedItem = localStorage.getItem(key);
    let item = null;
    if (cryptedItem) {
      const bytes = CryptoJS.AES.decrypt(
        cryptedItem,
        environment.encryptionKey,
      );
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      item = JSON.parse(originalText);
    }
    return item;
  }
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
  static setUnknownTypeItem(key: string, item: unknown) {
    const cryptedItem = CryptoJS.AES.encrypt(
      JSON.stringify(item),
      environment.encryptionKey,
    );
  }

  static clear() {
    localStorage.clear();
  }
}
