import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StrapiUtilsService {
  constructor() {}

  // Fonction pour traiter les données et obtenir les objets souhaités
  parseResponseData(response, niveau = 5) {
    niveau--;
    if (niveau < 0) {
      console.error(
        "Attention: Récursion maximum atteinte pour parseResponseData",
      );
      console.error(response);
      return response;
    }
    if (!response || !(response instanceof Object)) {
      return response;
    }
    // gère les champs objet qui contiennent des champs relationnels
    if (!response.data) {
      if (response instanceof Array) {
        let array = [];
        let entries = Object.entries(response);
        entries.forEach((entry) => {
          array[entry[0]] = this.parseObject(entry[1], niveau);
        });
        return array;
      }

      return this.parseObject(response, niveau);
    }
    if (response.data.length === 0) {
      return response.data;
    }

    // Récupérer les données des piliers depuis responseData.data
    const datas = response.data;

    // Obtenir les propriétés disponibles pour un objet à partir du premier élément
    let properties;
    if (datas[0]) {
      properties = Object.keys(datas[0].attributes);

      // Mapper les données des objets pour obtenir l'objet souhaité
      const resultList = datas.map((pilierData) => {
        const { id, attributes } = pilierData;
        const myObject = { id };

        // Ajouter les propriétés dynamiquement à l'objet
        properties.forEach((property) => {
          //myObject[property] = attributes[property];
          // On parse récursivement
          myObject[property] = this.parseResponseData(
            attributes[property],
            niveau,
          );
        });

        return myObject;
      });
      return resultList;
    } else {
      // Mapper les données des objets pour obtenir l'objet souhaité
      const { id, attributes } = datas;
      const myObject = { id };

      // Ajouter les propriétés dynamiquement à l'objet
      for (const [key, value] of Object.entries(attributes)) {
        myObject[key] = this.parseResponseData(value, niveau);
      }

      return myObject;
    }
  }

  parseObject(response, niveau) {
    if (!(response instanceof Object)) {
      return response;
    }
    let object = {};
    let properties = Object.keys(response);
    properties.forEach((property) => {
      if (response[property] instanceof Object && response[property].data) {
        response[property] = this.parseResponseData(response[property], niveau);
      } else if (
        response[property] instanceof Object &&
        response[property].data === null
      ) {
        if (Object.keys(response[property]).length == 1) {
          response[property] = response[property].data;
        }
      }
      object[property] = response[property];
      if (property == "data") {
        object = response[property];
      }
    });
    return object;
  }
}
