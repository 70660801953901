import { Injectable } from "@angular/core";

import axios from "axios";

import { environment } from "../../environments/environment";
import { AgeRange, Departement, Gender } from "../types/user.type";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  tmpUrl: string;
  apiUrl: string = environment.serverApiUrl;

  constructor() {}

  async register(
    email: string,
    password: string,
    username: string,
    firstname: string,
    genre: Gender,
    ageRange: AgeRange,
    departement: Departement,
    avatar?: File | null,
  ) {
    try {
      const { user, jwt: token } = await this.registerUser({
        email,
        password,
        username,
        firstname,
        genre,
        ageRange,
        departement,
      });

      if (avatar) {
        const avatarId = await this.uploadAvatar(avatar);
        await this.assignAvatarToUser(user.id, avatarId, token);
      }

      return { user, jwt: token };
    } catch (error) {
      console.error("Error registering user:", error);
      throw error.response;
    }
  }

  async login(email: string, password: string) {
    try {
      const response = await axios.post(`${this.apiUrl}/auth/local`, {
        identifier: email,
        password: password,
      });
      this.refreshSession();
      //console.log("DATA:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error.response;
    }
  }

  async refreshToken(refreshToken: string) {
    try {
      //console.log("RefreshToken envoyé: ", refreshToken);
      const response = await axios.get(
        `${this.apiUrl}/auth/refreshToken?token=` + refreshToken,
      );
      /*console.log("DATA:", response.data);
      console.log("RefreshToken reçu: ", response.data.refreshToken);*/
      this.refreshSession();

      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error.response;
    }
  }

  async forgotPassword(email: string) {
    try {
      const response = await axios.post(`${this.apiUrl}/auth/forgot-password`, {
        email,
      });
      return response.data;
    } catch (error) {
      console.error("Error on reset password in:", error);
      throw error.response;
    }
  }

  async resetPasswordWithStrapi(
    code: string,
    password: string,
    passwordConfirmation: string,
  ) {
    try {
      const response = await axios.post(`${this.apiUrl}/auth/reset-password`, {
        code,
        password,
        passwordConfirmation,
      });
      return response.data;
    } catch (error) {
      console.error("Error on reset password in:", error);
      throw error.response;
    }
  }

  async sendEmailConfirmation(email: string) {
    try {
      const response = await axios.post(
        `${this.apiUrl}/auth/send-email-confirmation`,
        {
          email,
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error on reset password in:", error);
      throw error.response;
    }
  }

  private async registerUser(userInfo: any) {
    const response = await axios.post(
      `${this.apiUrl}/auth/local/register`,
      userInfo,
    );
    return response.data;
  }

  private async uploadAvatar(avatar: File) {
    const formData = new FormData();
    formData.append("files", avatar);

    const response = await axios.post(`${this.apiUrl}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data[0].id;
  }

  private async assignAvatarToUser(
    userId: number,
    avatarId: number,
    token: string,
  ) {
    await axios.put(
      `${this.apiUrl}/users/${userId}`,
      {
        avatar: avatarId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  private refreshSession() {
    sessionStorage.setItem("lastLogin", Date.now() + "");
  }
}
