/* eslint-disable max-len */
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/utils/auth-guard.service";
import { FirstConnectPageModule } from "./pages/first-connect/first-connect.module";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  /*{
    path: "",
    loadChildren: () =>
      import("./pages/walkthrough/walkthrough.module").then(
        (m) => m.WalkthroughPageModule
      ),
  },*/
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterPageModule,
      ),
  },
  {
    path: "password-forgot",
    loadChildren: () =>
      import("./pages/password-forgot/password-forgot.module").then(
        (m) => m.PasswordForgotPageModule,
      ),
  },
  {
    path: "confirm-reg",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterPageModule,
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule,
      ),
  },
  {
    path: "walkthrough",
    loadChildren: () =>
      import("./pages/walkthrough/walkthrough.module").then(
        (m) => m.WalkthroughPageModule,
      ),
  },
  {
    path: "pilier",
    loadChildren: () =>
      import("./pages/pilier/pilier.module").then((m) => m.PilierPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "abonnement",
    loadChildren: () =>
      import("./pages/abonnement/abonnement.module").then(
        (m) => m.AbonnementPageModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "liens-utiles",
    loadChildren: () =>
      import("./pages/liens-utiles/liens-utiles.module").then(
        (m) => m.LiensUtilesPageModule,
      ),
  },
  {
    path: "lexique",
    loadChildren: () =>
      import("./pages/lexique/lexique.module").then((m) => m.LexiquePageModule),
  },
  {
    path: "bibliotheque",
    loadChildren: () =>
      import("./pages/bibliotheque/bibliotheque.module").then(
        (m) => m.BibliothequePageModule,
      ),
  },
  {
    path: "reset-password-modal",
    loadChildren: () =>
      import(
        "./components/reset-password-modal/reset-password-modal.module"
      ).then((m) => m.ResetPasswordModalPageModule),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./pages/faq/faq.module").then((m) => m.FaqPageModule),
  },
  {
    path: "legal-notice",
    loadChildren: () =>
      import("./pages/legal-notice/legal-notice.module").then(
        (m) => m.LegalNoticePageModule,
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyPageModule,
      ),
  },
  {
    path: "general-terms-of-use-and-sales",
    loadChildren: () =>
      import(
        "./pages/general-terms-of-use-and-sales/general-terms-of-use-and-sales.module"
      ).then((m) => m.GeneralTermsOfUseAndSalesPageModule),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactPageModule),
  },
  {
    path: "abonnement-formulaire",
    loadChildren: () =>
      import("./pages/abonnement-formulaire/abonnement-formulaire.module").then(
        (m) => m.AbonnementFormulairePageModule,
      ),
  },
  {
    path: "first-connect",
    loadChildren: () =>
      import("./pages/first-connect/first-connect.module").then(
        (m) => m.FirstConnectPageModule,
      ),
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./pages/not-found/not-found.module").then(
        (m) => m.NotFoundPageModule,
      ),
  },
  {
    path: "piliers-de-ma-sante",
    loadChildren: () =>
      import("./pages/piliers-de-ma-sante/piliers-de-ma-sante.module").then(
        (m) => m.PiliersDeMaSantePageModule,
      ),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
