import { Step } from "../types/parcours.type";

export default class Constants {
  static readonly defaultSteps: Step[] = [
    {
      id: 1,
      title: "Introduction",
      shortTitle: "Intro",
      isActive: true,
      isFinished: false,
    },
    {
      id: 2,
      title: "Go-Quiz",
      isActive: false,
      isFinished: false,
    },
    {
      id: 3,
      title: "Information",
      shortTitle: "Infos",
      isActive: false,
      isFinished: false,
    },
    {
      id: 4,
      title: "Mes notes",
      isActive: false,
      isFinished: false,
    },
    {
      id: 5,
      title: "Mes actions",
      isActive: false,
      isFinished: false,
    },
    {
      id: 6,
      title: "Finish\nLine Quiz",
      shortTitle: "FLQ",
      isActive: false,
      isFinished: false,
    },
  ];
}
