import {
  Injectable,
  WritableSignal,
  computed,
  inject,
  signal,
} from "@angular/core";
import { Pilier } from "../types/pilier.type";
import { StrapiHttpService } from "./utils/strapi-http.service";

@Injectable({
  providedIn: "root",
})
export class PilierService {
  private httpService: StrapiHttpService = inject(StrapiHttpService);
  private _pillars: WritableSignal<Pilier[]> = signal([]);
  public pillars = computed(() => this._pillars());
  private readonly apiUrl = "/piliers";
  private stopWaiting = false;

  async loadAllPiliers() {
    const pillars: Pilier[] = await this.httpService.get(this.apiUrl, {
      populate: "*",
    });
    pillars.sort(this.sortByOrder);
    pillars.forEach((pilier) => {
      pilier.modules.sort(this.sortByOrder);
    });
    this._pillars.set(pillars);
  }

  private sortByOrder(a: { order: number }, b: { order: number }): number {
    return a.order - b.order;
  }

  getPilier(id: number): Pilier | undefined {
    return this.pillars().find((pillar: Pilier) => pillar.id == id);
  }

  waitUntilPilierIsReady() {
    this.stopWaiting = false;
    return new Promise<boolean>((resolve) => {
      const interval = setInterval(() => {
        if (this.pillars().length > 0 || this.stopWaiting) {
          clearInterval(interval);
          resolve(this.stopWaiting);
        }
      }, 500);
    });
  }
}
