import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  constructor() {}

  private isMobileSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  isMobile$: Observable<boolean> = this.isMobileSubject.asObservable();

  updateIsMobile(isMobile: boolean) {
    this.isMobileSubject.next(isMobile);
  }
}
