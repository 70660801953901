import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "../user.service";
import { StrapiUtilsService } from "./strapi-utils.service";
import { StrapiHttpService } from "./strapi-http.service";

@Injectable({
  providedIn: "root",
})
export class StrapiAuthHttpService extends StrapiHttpService {
  constructor(
    private userService: UserService,
    http: HttpClient,
    strapiUtilsService: StrapiUtilsService,
  ) {
    super(http, strapiUtilsService);
  }

  protected async headers(): Promise<HttpHeaders> {
    const headers = await super.headers();
    const jwt = await this.userService.getJwt();
    return headers.set("Authorization", `Bearer ${jwt}`);
  }
}
