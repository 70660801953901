import { Injectable } from "@angular/core";
import { FinishLineQuizz, GoQuizz } from "src/app/types/quizz.type";
import { Module } from "../types/module.type";
import { Question } from "../types/question.type";
import { PilierService } from "./pilier.service";
import { StrapiHttpService } from "./utils/strapi-http.service";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  readonly apiUrl = "/modules";
  readonly apiQuestionUrl = "/questions";
  readonly apiGoQuizzUrl = "/go-quizzes";
  readonly apiFinishLineQuizzUrl = "/finish-line-quizzes";

  constructor(
    private pilierService: PilierService,
    private strapiHttpService: StrapiHttpService,
  ) {}

  async getAllModules(): Promise<Module[]> {
    const allModules: Module[] = await this.strapiHttpService.getAll(
      this.apiUrl,
      { populate: "*" },
    );

    // TODO replace by url params
    let allModulesSorted = [];
    if (allModules?.length > 0) {
      this.pilierService.pillars().forEach((pilier) => {
        const modulesPilerSorted = allModules
          .filter((m) => m.pilier.id === pilier.id)
          .sort((a, b) => a.order - b.order);
        allModulesSorted = [...allModulesSorted, ...modulesPilerSorted];
      });
    }
    return allModulesSorted;
  }

  async getGoQuizQuestions(currentModuleId: number): Promise<Question[]> {
    return this.getQuestions(currentModuleId, "GoQuizz");
  }

  async getFinishLineQuizQuestions(
    currentModuleId: number,
  ): Promise<Question[]> {
    return this.getQuestions(currentModuleId, "FinishLineQuizz");
  }

  async getQuestions(
    currentModuleId: number,
    quizz: string,
  ): Promise<Question[]> {
    return this.strapiHttpService
      .getAll<Question>(this.apiQuestionUrl, {
        "filters[module][id][$eq]": currentModuleId,
        "filters[quizz][$eqi]": quizz,
        populate: "*",
        "pagination[pageSize]": 1000,
      })
      .then((questions) => this.shuffle(questions));
  }

  async getModule(currentModule: Module): Promise<Module> {
    return this.strapiHttpService.getFirst(this.apiUrl, {
      "filters[id][$eq]": currentModule.id,
      populate: "margueriteResponse,petales,bannerImg",
    });
  }

  async getGoQuizz(currentModuleId: number): Promise<GoQuizz> {
    return this.strapiHttpService.getFirst(this.apiGoQuizzUrl, {
      "filters[module][id][$eq]": currentModuleId,
      populate: "*",
    });
  }

  async getFinishLineQuizz(currentModuleId: number): Promise<FinishLineQuizz> {
    return this.strapiHttpService.getFirst(this.apiFinishLineQuizzUrl, {
      "filters[module][id][$eq]": currentModuleId,
      populate: "*",
    });
  }

  private shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length;
    let temporaryValue: T;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
}
