import { Injectable } from "@angular/core";
import { UserAction } from "../types/user-action.type";
import { LocalStorageService } from "./utils/local-storage.service";
import { Module } from "../types/module.type";
import { ModuleService } from "./module.service";

export type UserActions = { [actionId: number]: UserAction };

@Injectable({
  providedIn: "root",
})
export class UserActionService {
  // FIXME store data in backend

  constructor(private moduleService: ModuleService) {}

  async selectAction(moduleId: number, userAction: UserAction): Promise<void> {
    const selectedActions = this.getUserActions(moduleId);
    selectedActions[userAction.actionItemId] = userAction;
    console.debug("selectAction", userAction);
    this.setUserActions(moduleId, selectedActions);
  }

  async unselectAction(
    moduleId: number,
    userAction: UserAction,
  ): Promise<void> {
    const selectedActions = this.getUserActions(moduleId);
    delete selectedActions[userAction.actionItemId];
    console.debug("unselectAction", userAction);
    this.setUserActions(moduleId, selectedActions);
  }

  async updateSelectedAction(
    moduleId: number,
    userAction: UserAction,
  ): Promise<void> {
    const selectedActions = this.getUserActions(moduleId);
    selectedActions[userAction.actionItemId] = userAction;
    console.debug("updateSelectedAction", userAction);
    this.setUserActions(moduleId, selectedActions);
  }

  async getSelectedActions(
    moduleId: number,
  ): Promise<{ [actionId: number]: UserAction }> {
    return this.getUserActions(moduleId);
  }

  private setUserActions(moduleId: number, actions: UserActions) {
    return LocalStorageService.setItem(this.myStorageKey(moduleId), actions);
  }

  private getUserActions(moduleId: number): UserActions {
    return LocalStorageService.getItem(this.myStorageKey(moduleId)) ?? {};
  }

  private deleteUserAction(moduleId: number): void {
    LocalStorageService.removeItem(this.myStorageKey(moduleId));
  }

  private myStorageKey(moduleId: number): string {
    return "user-actions-" + moduleId;
  }

  async deleteAllUserActions() {
    const allModules: Module[] = await this.moduleService.getAllModules();
    allModules.forEach((module) => this.deleteUserAction(module.id));
  }
}
