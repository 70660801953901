import {
  Injectable,
  WritableSignal,
  computed,
  inject,
  signal,
} from "@angular/core";
import { Parcours, Session, StatePilier } from "src/app/types/parcours.type";
import Constants from "../constants/constants";
import { IdOrderModule, Module } from "../types/module.type";
import { Pilier } from "../types/pilier.type";

import { ModuleAffirmations } from "../types/pilier.type";
import { Question } from "../types/question.type";
import { QuizResponse } from "../types/quizz.type";
import { PilierService } from "./pilier.service";
import { UserActionService } from "./user-action.service";
import { UserNotesService } from "./user-notes.service";
import { UserService } from "./user.service";
import { StrapiAuthHttpService } from "./utils/strapi-auth-http.service";

@Injectable({
  providedIn: "root",
})
export class ParcoursService {
  // ATTRIBUTS

  static readonly apiUrl = "/parcours";

  private userNoteService: UserNotesService = inject(UserNotesService);
  private userActionService: UserActionService = inject(UserActionService);
  private strapiHttpService: StrapiAuthHttpService = inject(
    StrapiAuthHttpService,
  );
  private userService: UserService = inject(UserService);

  private pilierService: PilierService = inject(PilierService);
  public pillars = this.pilierService.pillars;

  private _parcours: WritableSignal<Parcours> = signal(null);
  public parcours = computed(() => this._parcours());

  // METHODES

  private async createParcours() {
    const parcours = this.getBlankParcours(this.userService.user.id);
    parcours.session = this.getNewSession(
      parcours.statePilier[0].pilier.id,
      parcours.statePilier[0].currentModule,
    );
    const response = await this.strapiHttpService.post<Parcours>(
      `${ParcoursService.apiUrl}/mine`,
      parcours,
    );
    parcours.id = response.id;
    this._parcours.set(parcours);
  }

  private async resetParcours() {
    const parcours = this.getBlankParcours(this.userService.user.id);
    parcours.session = this.getNewSession(
      parcours.statePilier[0].pilier.id,
      parcours.statePilier[0].currentModule,
    );
    const response = await this.strapiHttpService.put<Parcours>(
      `${ParcoursService.apiUrl}/mine`,
      parcours,
    );
    parcours.id = response.id;
    this._parcours.set(parcours);
  }

  private async getParcours() {
    const parcours: Parcours = await this.strapiHttpService.getFirst(
      `${ParcoursService.apiUrl}/mine`,
      {
        populate:
          "session.pilier,session.module,statePilier.pilier,user,selectedAffirmations",
      },
    );
    this._parcours.set(parcours);
  }

  private async updateParcours(updateFn: (Parcours) => Parcours) {
    this._parcours.update(updateFn);
    await this.strapiHttpService.put<Parcours>(
      `${ParcoursService.apiUrl}/mine`,
      this.parcours(),
    );
    // LOGS a reutiliser pour tenter d'enregistrer les affirmations selectionnées dans Strapi
    // .then((message) => {
    //   console.log("updateParcours SUCCESS", message); // La promesse est résolue
    // })
    // .catch((error) => {
    //   console.log("updateParcours FAILURE", error); // La promesse n'est pas résolue
    // });
  }

  public async loadParcours() {
    await this.getParcours();
    if (!this.parcours()) {
      await this.createParcours();
    }
  }

  isCurrentPillar(pillarId): boolean {
    return this.parcours()?.session?.pilier.id === pillarId;
  }

  isCurrentModule(moduleId): boolean {
    return this.parcours()?.session?.currentModule?.id === moduleId;
  }

  private getBlankParcours(userId: number): Parcours {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      user: userId,
      session: null,
      statePilier: this.pillars().map((pillar, index) => ({
        pilier: pillar,
        isActivated: index === 0 ? true : false,
        isTermited: false,
        currentModule: pillar.modules[0],
        modulesId: pillar.modules.map((module) => ({
          id: module.id,
          order: module.order,
        })),
      })),
      abonnement: null,
      selectedAffirmations: [],
    };
  }

  private getNewSession(pillarId, currentModule): Session {
    return {
      pilier: { id: pillarId } as Pilier,
      currentModule: new IdOrderModule(currentModule.id, currentModule.order),
      steps: JSON.parse(JSON.stringify(Constants.defaultSteps)),
      currentStep: null,
      goQuizzOrder: null,
      finishLineQuizzOrder: null,
      goQuizzResponses: [],
      finishLineQuizzResponses: [],
      module: null,
      id: null,
    };
  }

  startSession(pillarId, currentModule) {
    this.updateParcours((parcours) => {
      parcours.session = this.getNewSession(pillarId, currentModule);
      return parcours;
    });
  }

  async resetProgress() {
    this.userNoteService.resetAllUserNotes();
    this.userActionService.deleteAllUserActions();
    // Clear every affirmations
    this.pillars()
      .flatMap((pillar) => pillar.modules)
      .map((module: Module) => module.title)
      .forEach((moduleTitle) => localStorage.removeItem(moduleTitle));
    await this.resetParcours();
  }

  finishModule(pilierId: number) {
    this.updateParcours((parcours) => {
      const currentPilierState = parcours.statePilier.find((state) => {
        return state.pilier.id == pilierId;
      });
      if (currentPilierState != undefined) {
        currentPilierState.isTermited = true;
      }
      parcours.session.steps.find((step) => step.id === 6).isFinished = true;
      return parcours;
    });
  }

  /**
   * Fait évoluer le parcours au debut d'un nouveau module
   * @param target { pilierId: number; moduleId: number } pilierId : id du pilier du nouveau module / moduleId : id du nouveau module
   */
  startNewModule(target: { pilierId: number; moduleId: number }) {
    this.updateParcours((parcours) => {
      const currentPilierId = parcours.session.pilier.id;
      if (target.pilierId != currentPilierId) {
        const previousPilierState: StatePilier =
          this.parcours().statePilier.find(
            (pillarState) => pillarState.pilier.id == currentPilierId,
          );
        if (previousPilierState) {
          previousPilierState.isTermited = true;
        }
        const newPilierState: StatePilier | undefined =
          parcours.statePilier.find(
            (statePilier) => statePilier.pilier.id == target.pilierId,
          );
        if (newPilierState) {
          newPilierState.isActivated = true;
        }
        parcours.session.pilier.id = target.pilierId;
      }

      const newCurrentModule: Module | undefined = this.pilierService
        .getPilier(target.pilierId)
        ?.modules.find((module) => module.id == target.moduleId);
      if (newCurrentModule) {
        parcours.session.currentModule.id = newCurrentModule.id;
        parcours.session.currentModule.order = newCurrentModule.order;
        parcours.session.steps.forEach((step) => {
          step.isActive = false;
          step.isFinished = false;
        });
        parcours.session.steps[0].isActive = true;
        parcours.session.currentStep = parcours.session.steps[0];
      }
      return parcours;
    });
  }

  private updateStep(parcours: Parcours, stepId: number): Parcours {
    parcours?.session?.steps.forEach((step) => {
      step.isActive = stepId === step.id;
    });
    parcours.session.currentStep = Constants.defaultSteps.find(
      (step) => step.id === stepId,
    );
    return parcours;
  }

  finishStep(stepId: number) {
    this.updateParcours((parcours) => {
      parcours.session.steps.find((step) => step.id === stepId).isFinished =
        true;
      parcours = this.updateStep(parcours, stepId + 1);
      return parcours;
    });
  }

  goToStep(stepId: number) {
    this.updateParcours((parcours) => this.updateStep(parcours, stepId));
  }

  loadOrSaveQuestionOrder(questions: Question[], type: string): Question[] {
    const sortedQuestionIdsJoined = questions
      .map((question) => question.id)
      .sort()
      .join();

    if (
      type === "GoQuizz" &&
      !!this.parcours().session?.goQuizzOrder?.length &&
      sortedQuestionIdsJoined ===
        this.parcours().session.goQuizzOrder.sort().join()
    ) {
      return this.parcours().session.goQuizzOrder.map((order) =>
        questions.find((item) => item.id === order),
      );
    }

    if (
      type === "FinishLineQuizz" &&
      !!this.parcours().session?.finishLineQuizzOrder?.length &&
      sortedQuestionIdsJoined ===
        this.parcours().session.finishLineQuizzOrder.sort().join()
    ) {
      return this.parcours().session.finishLineQuizzOrder.map((order) =>
        questions.find((item) => item.id === order),
      );
    }

    return this.saveQuestionOrder(questions, type);
  }

  saveQuestionOrder(questions: Question[], type: string): Question[] {
    const quizzOrder: number[] = questions.map((question) => question.id);

    if (type === "GoQuizz") {
      this.updateParcours((parcours) => {
        parcours.session.goQuizzOrder = quizzOrder;
        return parcours;
      });
    } else if (type === "FinishLineQuizz") {
      this.updateParcours((parcours) => {
        parcours.session.finishLineQuizzOrder = quizzOrder;
        return parcours;
      });
    }

    return questions;
  }

  initResponses(type: string, questions) {
    if (
      (type === "GoQuizz" &&
        !!this.parcours().session?.goQuizzResponses.length) ||
      (type === "FinishLineQuizz" &&
        !!this.parcours().session?.finishLineQuizzResponses.length)
    ) {
      return;
    }

    const responses: QuizResponse[] = questions.map((index: number) => ({
      index,
      responses: [],
    }));

    this.updateParcours((parcours) => {
      if (type === "GoQuizz") {
        parcours.session.goQuizzResponses = responses;
      } else if (type === "FinishLineQuizz") {
        parcours.session.finishLineQuizzResponses = responses;
      }
      return parcours;
    });
  }

  resetFinishLineQuizzUserResponses(questions) {
    this.updateParcours((parcours) => {
      parcours.session.finishLineQuizzResponses = questions.map(
        (index: number) => ({
          index,
          responses: [],
        }),
      );
      return parcours;
    });
  }

  updateResponses(index: number, responses: string[], type: string) {
    console.log("----goQuizzResponses:");
    console.log(this.parcours().session.goQuizzResponses);
    console.log("----Responses:");
    console.log(responses);
    this.updateParcours((parcours) => {
      // console.log("UPDATING", parcours);
      if (type === "GoQuizz") {
        parcours.session.goQuizzResponses[index].responses = responses;
      } else if (type === "FinishLineQuizz") {
        parcours.session.finishLineQuizzResponses[index].responses = responses;
      }
      return parcours;
    });
  }

  getEmptyResponses(type: string) {
    let responses = [];
    if (type === "GoQuizz") {
      responses = this.parcours().session.goQuizzResponses;
    } else if (type === "FinishLineQuizz") {
      responses = this.parcours().session.finishLineQuizzResponses;
    }
    return responses
      .map((item, index) => ({ item: item, index: index }))
      .filter((res) => res.item.responses.length === 0);
  }

  // Chaque réponse vaut 1 point. Les réponses à choix multiple valent n points, 'n' étant le nombre de réponses justes attendues.
  // Le socre = n1(nombre total de réponse justes) / n2(nombre total réponses justes attendues) * 100.
  calculateModuleScore(questions: any[]) {
    let n1 = 0;
    let n2 = 0;
    for (let i = 0; i < questions.length; i++) {
      // Pour chaque question
      const question = questions[i];
      for (let j = 0; j < question.responses.length; j++) {
        // Pour chaque réponse juste
        const response = question.responses[j];
        if (response.isValid) {
          // Pour chaque réponse juste
          n2++;
          const userResponses =
            this.parcours().session.finishLineQuizzResponses[i];
          if (userResponses.responses.includes(response.name)) {
            // Si la réponse juste fait partie des réponses du user
            n1++;
          }
        }
      }
    }

    const score = Math.round((n1 / n2) * 100);
    return score;
  }

  // NE FONCTIONNE PAS
  // !TODO : faire fonctionner la partie Strapi
  /**
   * Sauvegarde les affirmations d'une variable de type `ModuleAffirmations[]` dans le parcours.service et le strapi
   * @param selectedAffirmations les affirmations à sauvegarder
   * @returns void
   */
  saveAffirmations(selectedAffirmations: ModuleAffirmations[]): void {
    // // console.log("Affirmations à sauvegarder", selectedAffirmations);
    // let newAffirmations: ParcoursAffirmation[] = [];
    // // Pour chaque Module
    // selectedAffirmations.forEach((moduleAffirmations: ModuleAffirmations) => {
    //   // Pour chaque affirmation
    //   moduleAffirmations.selections.forEach((affirmation: Affirmation) => {
    //     // console.log("saving", {
    //     //   pilierid: 1,
    //     //   moduleid: moduleAffirmations.moduleId,
    //     //   affirmations: JSON.stringify({
    //     //     title: affirmation.title,
    //     //     type: affirmation.type,
    //     //   }),
    //     // });
    //     newAffirmations.push({
    //       pilierid: 1,
    //       moduleid: moduleAffirmations.moduleId,
    //       affirmations: JSON.stringify({
    //         title: affirmation.title,
    //         type: affirmation.type,
    //       }),
    //     });
    //   });
    // });
    // let currentParcours = this.parcours();
    // if (currentParcours) {
    //   currentParcours.selectedAffirmations = newAffirmations;
    // }
    // this.updateParcours((updatedParcours) => {
    //   console.log("Trying to update with ", updatedParcours);
    //   return updatedParcours;
    // });
  }

  hasNextModule(pilier) {
    return pilier.modules.find(
      (module) =>
        module.order === this.parcours().session.currentModule.order + 1,
    );
  }
  get currentAdvancementNumber(): number {
    const maxStep = this.parcours().session?.steps.find(
      (step) => !step.isFinished,
    );
    return maxStep ? maxStep.id : this.nbSteps;
  }
  get nbSteps(): number {
    return this.parcours().session?.steps.length ?? 0;
  }
}
