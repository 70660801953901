/* eslint-disable max-len,@typescript-eslint/naming-convention */
import { Injectable } from "@angular/core";

import axios from "axios";

import { environment } from "../../environments/environment";
import { LocalStorageService } from "./utils/local-storage.service";
import { AuthService } from "./auth.service";
import { User } from "../types/user.type";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public apiUrl = environment.serverApiUrl;
  user: User = null;
  jwt: any = null;
  defaultAvatar: string = "assets/img/logo2.png";

  setting: any = null;

  stopWaiting = false;

  constructor(private auth: AuthService) {}

  setUserAuth(userAuth: any) {
    this.setUserItem(userAuth);
    this.setTokenItem(userAuth.jwt);
    this.setRefreshToken(userAuth.refreshToken);
  }

  setUserItem(userAuth: any) {
    this.user = userAuth.user;
    LocalStorageService.setItem("userAuth", userAuth);
  }

  setTokenItem(token: string) {
    this.jwt = token;
    LocalStorageService.setItem("jwt", token);
  }

  setRefreshToken(token: string) {
    LocalStorageService.setItem("refreshToken", token);
  }

  async getJwt(): Promise<string> {
    let token: string = this.jwt;
    if (token == null) {
      token = LocalStorageService.getItem("jwt");
    }
    if (token != null) {
      let tokenParts: string[] = token.split(".");
      if (tokenParts.length < 2) {
        console.error("Invalid token formation " + token);
        return null;
      }
      // checking if the token hasn't expired
      let tokenPayloadStr = atob(tokenParts[1]);
      let tokenPayload = JSON.parse(tokenPayloadStr);
      let exp: number = parseInt(tokenPayload.exp);
      let nowTimestampSecs: number = Math.floor(Date.now() / 1000);
      if (exp < nowTimestampSecs) {
        console.log("refreshToken");
        LocalStorageService.removeItem("jwt");
        let refreshToken: string = LocalStorageService.getItem("refreshToken");
        let tokens = await this.auth.refreshToken(refreshToken);
        token = tokens.jwt;
        refreshToken = tokens.refreshToken;
        if (token == null) {
          this.logout();
        }
        this.setTokenItem(token);
        this.setRefreshToken(refreshToken);
      }
    }
    return token;
  }

  loadUserAuth() {
    try {
      const userAuth = LocalStorageService.getItem("userAuth");
      this.user = userAuth.user;
      this.jwt = userAuth.jwt;
      window.dispatchEvent(
        new CustomEvent("END_OF_LOAD_USER_DATA", {
          detail: "END OF LOADING USER DATA",
        }),
      );
    } catch (error) {
      window.dispatchEvent(new CustomEvent("LOGOUT", { detail: "LOG OUT" }));
    }
  }

  isLoggedIn() {
    return !!this.user;
  }

  getAvatar() {
    return this.user.avatar
      ? environment.serverUrl + this.user.avatar?.url
      : this.defaultAvatar;
  }

  isSubscribed() {
    return !!this.user.abonnement;
  }

  logout() {
    this.user = null;
    LocalStorageService.clear();
    /** 
    localStorage.removeItem("setting");
    localStorage.removeItem("userAuth");
    localStorage.removeItem("jwt");
    localStorage.removeItem("parcours");
    */
    // add your code
  }

  async deleteAccount() {
    // TODO: Suppression ou archivages du user from STRAPI
    // TODO: Suppression ou archivages des données qui sont dans d'autres collections
    // TODO: Supprimer aussi les images associée, y compris la photo de profil si elle existe
  }

  waitUntilUserDataIsReady() {
    this.stopWaiting = false;
    return new Promise<boolean>((resolve) => {
      const interval = setInterval(() => {
        if (this.user || this.stopWaiting) {
          clearInterval(interval);
          resolve(this.stopWaiting);
        }
      }, 500);
    });
  }

  async fetchCurrentUser(jwtToken?: string) {
    try {
      const storedToken = LocalStorageService.getItem("jwt");
      const token = jwtToken ?? storedToken;
      const response = await axios.get(`${this.apiUrl}/users/me?populate=*`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching current user:", error);
      throw error;
    }
  }

  private async uploadAvatar(avatar: File) {
    const formData = new FormData();
    formData.append("files", avatar);

    const response = await axios.post(`${this.apiUrl}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data[0].id;
  }

  private async assignAvatarToUser(
    userId: number,
    avatarId: number,
    token: string,
  ) {
    await axios.put(
      `${this.apiUrl}/users/${userId}`,
      {
        avatar: avatarId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  private async updateUser(userId: number, userInfo: any, token: string) {
    const { firstname, genre, ageRange, departement } = userInfo;
    const response = await axios.put(
      `${this.apiUrl}/users/${userId}`,
      {
        firstname,
        genre,
        ageRange,
        departement,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  }

  async updateUserProfile(
    firstname,
    genre,
    ageRange,
    departement,
    avatar?: File | null,
  ) {
    try {
      const storedToken = LocalStorageService.getItem("jwt");
      const token = this.jwt ?? storedToken;
      const userId = this.user.id;

      if (avatar) {
        const avatarId = await this.uploadAvatar(avatar);
        await this.assignAvatarToUser(userId, avatarId, token);
      }

      const response = await this.updateUser(
        userId,
        {
          firstname,
          genre,
          ageRange,
          departement,
        },
        token,
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching current user:", error);
      throw error;
    }
  }

  async updateUserPassword(
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
  ) {
    try {
      const storedToken = LocalStorageService.getItem("jwt");
      const token = this.jwt ?? storedToken;
      const userId = this.user.id;

      // update
      const response = await axios.post(
        `${this.apiUrl}/auth/change-password`,
        {
          currentPassword,
          password,
          passwordConfirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
