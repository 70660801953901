import { RichText } from "./rich-text.type";
import { Identified, StrapiAudited } from "./strapi-audited.type";
import { StrapiMedia } from "./strapi-media.type";

export interface Module extends StrapiAudited {
  title: string;
  introduction: RichText;
  bgColor: string;
  introductionNotes: RichText;
  pilier: Identified;
  order: number;
  id: number;
  showTitle: boolean;

  bannerImg?: StrapiMedia;
  // questions : TODO
  // infos : TODO
  // go_quizz
  maxAmountOfSelectedAffirmations: number;
  margueriteResponse: Array<{ title: string; type: string; bgColor: string }>;
}

export interface ModuleId {
  id: number;
  order: number;
}

export class IdOrderModule implements Module {
  constructor(id: number, order: number) {
    this.id = id;
    this.order = order;
  }
  title: string;
  introduction: string;
  bgColor: string;
  introductionNotes: string;
  pilier: Identified;
  order: number;
  bannerImg?: StrapiMedia;
  maxAmountOfSelectedAffirmations: number;
  margueriteResponse: [];
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
  id: number;
  showTitle: boolean;
}
