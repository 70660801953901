import { Injectable } from "@angular/core";
import { Identified } from "src/app/types/strapi-audited.type";
import { UserNote } from "src/app/types/user-note.type";
import { StrapiAuthHttpService } from "./utils/strapi-auth-http.service";
import { ModuleService } from "./module.service";
import { Module } from "../types/module.type";

@Injectable({
  providedIn: "root",
})
export class UserNotesService {
  readonly API_URL = "/user-notes";

  constructor(
    private strapiHttp: StrapiAuthHttpService,
    private moduleService: ModuleService,
  ) {}

  async getUserNote(module: Identified): Promise<UserNote> {
    return this.strapiHttp.getFirst(this.API_URL + "/mine", {
      "filters[module][id][$eq]": module.id,
      populate: "user,module",
    });
  }

  async saveUserNote(userNote: Partial<UserNote>): Promise<UserNote> {
    return this.strapiHttp.post(this.API_URL + "/mine", userNote);
  }

  async updateUserNote(
    notesId: string,
    userNote: Partial<UserNote>,
  ): Promise<UserNote> {
    return this.strapiHttp.put(this.API_URL + "/" + notesId, userNote);
  }

  async resetAllUserNotes() {
    /* Récupère les notes de l'user */
    const allModules: Module[] = await this.moduleService.getAllModules();
    const promisedUserNotes: Promise<UserNote>[] = allModules.map((module) =>
      this.getUserNote(module),
    );
    const userNotes: UserNote[] = await Promise.all(promisedUserNotes);
    /* Reset les notes */
    userNotes
      .filter((userNote) => userNote)
      .forEach((userNote) => {
        userNote.notes = [];
        this.updateUserNote(userNote.uid, userNote);
      });
  }
}
